<template>
  <div>
    <div class="tile is-ancestor pt-6 mt-6">
      <div class="tile is-parent"></div>
      <div class="tile is-parent is-vertical is-6 mx-5 my-6">
        <div class="tile is-child"></div>
        <div class="tile is-child"></div>
        <div class="tile is-child">
          <article class="mx-5">
            <h1 class="title has-text-primary-dark has-text-right">
              {{ $t("teamheader") }}
            </h1>
            <p class="has-text-right ">{{ $t("teamtext") }}</p>
          </article>
        </div>
      </div>
      <div class="px-5 tile is-vertical is-6 has-text-right">
        <figure class="px-5">
          <img
            :src="require('@/assets/Team/IMG_8971.jpg')"
            width="850"
            alt=""
          />
        </figure>
      </div>
    </div>
    <div class="tile is-parent my-6 mx-6">
      <div class="tile is-child is-4"></div>
      <!--div class="tile is-child">
        <article>
          <h1 class="title has-text-primary-dark has-text-center">{{$t("storyheader")}}</h1>
          <p  class="has-text-center">{{$t("storytext")}}</p>
        </article>
      </div-->
      <div class="tile is-child is-4"></div>
    </div>
    <figure class="image crop">
      <img :src="require('@/assets/Home/IMG_9213.jpg')" alt="" />
    </figure>
  </div>
</template>

<script>
export default {};
</script>

<style>
p {
  white-space: pre;
}
.crop {
  width: 100%;
  height: 50vw;
  overflow: hidden;
}
</style>
